import React, { useState, Dispatch, SetStateAction, useEffect, useCallback, useRef } from "react"
import compute from "../../utils/compute"
import { IPosState, updateShowAddress, updateWalletAddress } from "../../store/posSlice"
import inputThunderCoreIcon from "../../assets/veTT.svg"
import { MAX_INPUT_AMOUNT, MAX_INPUT_FLOATING_POINT } from "../../constant/constants"
import { makeStyles } from "@mui/styles"
import { AnyAction } from "redux"
import { useWalletWrapper } from "../../context/Wallet"
import { BigNumber, utils } from "ethers"
import warningIcon from "../../assets/warning.svg"
import warningOutlineIcon from "../../assets/warningOutline.svg"
import Banner from "../Ad/Banner"
import { useTranslation } from "react-i18next"
import useExchangeRatio from "../../hooks/useExchangeRatio"

const UnStakeForm = (props: {
  pos: IPosState
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
  dispatch: Dispatch<AnyAction>
  setIsRequestingUnstake: Dispatch<SetStateAction<boolean>>
  setOpenModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { pos, inputValue, setInputValue, dispatch, setIsRequestingUnstake, setOpenModal } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [displayInput, setDisplayInput] = useState(() => inputValue)
  const { balance, walletAddress, showAddress, claimAmount, stakedVeTT } = pos.userStatus
  const { veTTTtRatio } = useExchangeRatio()

  const buttonDisabled = (inputValue === "" && showAddress) || claimAmount > 0
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: any) => {
    const removeChar = event.currentTarget.value.replace(/[^\d.]/g, "").replace(/(\..*)\./g, "$1")
    const [_, decimal] = removeChar.split(".")
    if (
      event.currentTarget.value < 0 ||
      Number(event.currentTarget.value) > MAX_INPUT_AMOUNT ||
      (decimal && decimal.length > MAX_INPUT_FLOATING_POINT)
    )
      return
    setInputValue(removeChar)
    setDisplayInput(compute.formatNumber(removeChar))
  }

  const { setisConnectWalletsModal } = useWalletWrapper()
  const autoConnectInTTWallet = useCallback(async () => {
    if ("hubId" in window) {
      const account: string[] = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: []
      })

      if (account.length > 0) {
        dispatch(updateWalletAddress(account[0]))
        dispatch(updateShowAddress(true))
      }
    }
  }, [dispatch])

  const handleConnectWallet = () => {
    if ("hubId" in window) autoConnectInTTWallet()
    else setisConnectWalletsModal(true)
  }

  const handleUnstakePressed = () => {
    if (buttonDisabled) return
    setIsRequestingUnstake(true)
    setOpenModal(true)
  }

  useEffect(() => {
    if (walletAddress !== "" && inputValue !== "") {
      if (BigNumber.from(stakedVeTT).lt(utils.parseUnits(inputValue, "ether"))) {
        setShowError(true)
        setErrorMessage(
          `Insufficient balance! Max Balance: ${compute.roundNumberShort(
            +utils.formatEther(stakedVeTT)
          )} veTT`
        )
      } else {
        setShowError(false)
        setErrorMessage("")
      }
    } else if (inputValue === "") {
      setDisplayInput("")
    } else {
      setShowError(false)
      setErrorMessage("")
    }
  }, [inputValue, balance, walletAddress, stakedVeTT])

  useEffect(() => {
    if (showError) {
      inputRef.current!.style.borderColor = "#E0392D"
    } else {
      inputRef.current!.style.borderColor = ""
    }
  }, [showError])

  return (
    <div
      className="`
      relative flex flex-col p-[16px] bg-white dark:bg-neutrals-900 rounded-[18px] md:p-[24px]
    `"
    >
      {claimAmount > 0 && (
        <div
          className={`
            absolute w-full h-full flex flex-col items-center justify-center z-10 left-0 top-0 px-[32px]
            border-[1px] border-solid border-br-light dark:border-neutrals-700 rounded-[18px]
            bg-white dark:bg-black bg-opacity-90 dark:bg-opacity-90 
          `}
        >
          <img src={warningOutlineIcon} className="w-[24px] h-[24px] mb-[4px]" alt="warning" />
          <span className="text-center text-[12px] leading-[24px] text-black dark:text-white">
            {t("staking.unstake.stillProcessing")}
          </span>
        </div>
      )}
      <div className="flex flex-row justify-between">
        <span className="text-[14px] leading-[20px] font-medium">{t("staking.unstake.title")}</span>
        <span className="text-secondary text-[12px] leading-[20px]">
          1 veTT ≈ {`${compute.roundNumber(veTTTtRatio, 4)}`} TT
        </span>
      </div>
      <div
        ref={inputRef}
        className={`
          flex flex-row items-center mt-[8px] space-x-[12px] p-[16px] rounded-[12px] border-[1px] border-solid border-br-light dark:border-neutrals-700 bg-neutrals-50 dark:bg-neutrals-800
        `}
      >
        <img src={inputThunderCoreIcon} className="w-[24px] h-[24px]" alt={"TT"} />
        <input
          id="unstake"
          name="unstake"
          type="text"
          inputMode="numeric"
          min="0"
          value={displayInput}
          onChange={handleChange}
          className={`${classes.input} text-black dark:text-white`}
          placeholder="0"
          onFocus={() => {
            inputRef.current!.classList.add("dark:border-secondary-500", "border-primary-700")
          }}
          onBlur={() => {
            inputRef.current!.classList.remove("dark:border-secondary-500", "border-primary-700")
          }}
        />
        <button
          onClick={() => {
            if (stakedVeTT === "0") return
            setInputValue(utils.formatEther(stakedVeTT))
            setDisplayInput(compute.formatNumber(Number(utils.formatEther(stakedVeTT))))
          }}
          className="btn btn-secondary w-[66px] h-[32px] text-[12px] leading-[20px] rounded-[6px]"
        >
          {t("staking.stake.max")}
        </button>
      </div>
      {showError && (
        <div className="text-error dark:text-dark-error mt-[4px] text-[12px] leading-[20px]">
          {errorMessage}
        </div>
      )}
      <div className="flex flex-row justify-between mt-[32px]">
        <div className="text-[12px] leading-[20px]">{t("staking.stake.youWillGet")}</div>
        <div className="text-[12px] leading-[20px]">
          {`${compute.roundNumberShort(Number(inputValue) * Number(veTTTtRatio))} TT`}
        </div>
      </div>
      <div className="flex flex-row mt-[8px] mb-[24px]">
        <img src={warningIcon} className="w-[16px] h-[16px] mr-[4px]" alt="warning" />
        <p className="text-[12px] leading-[20px] text-secondary dark:text-neutrals-500 ">
          {t("staking.unstake.desc")}
        </p>
      </div>
      <button
        onClick={() => (!showAddress ? handleConnectWallet() : handleUnstakePressed())}
        disabled={buttonDisabled}
        className="btn btn-secondary w-full h-[48px]"
      >
        {!showAddress ? t("staking.connect.connect") : t("staking.unstake.unstake")}
      </button>
      <Banner />
    </div>
  )
}
const useStyles = makeStyles({
  input: {
    "&::placeholder": {
      color: "#707488",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500
    },
    fontFamily: "Montserrat",
    width: "100%",
    borderWidth: 0,
    backgroundColor: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    outline: "none",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  }
})
export default UnStakeForm
