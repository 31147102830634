import React from "react"
import RamIcon from "../../assets/ram.png"
interface IBannerProps extends IBannerOwnProps {}

interface IBannerOwnProps {}

const Banner: React.FC<IBannerProps> = () => {
  return (
    <div
      onClick={() => {
        window.open("https://rammer.finance/", "_blank")
      }}
      className="flex flex-row items-center mt-[24px] py-[22px] px-[24px] space-x-[18px] border-[1px] border-solid border-[#10FF95] rounded-[10px] cursor-pointer"
      style={{
        background: "radial-gradient(76.44% 225.5% at 84.58% 68.5%, #1E1D1D 0%, #000000 100%)"
      }}
    >
      <img src={RamIcon} className="w-[56px] h-[56px]" alt="RAM protocol" />
      <div>
        <div className="text-[#10FF95] font-ceviche">RAM protocol</div>
        <p className="text-white text-[12px] mt-[4px]">
          Supply your veTT, unlock more investment opportunities.
        </p>
      </div>
    </div>
  )
}

export default Banner
