import React, { useState, Dispatch, SetStateAction, useEffect, useCallback, useRef } from "react"
import compute from "../../utils/compute"
import { IPosState, updateShowAddress, updateWalletAddress } from "../../store/posSlice"
import inputThunderCoreIcon from "../../assets/thundercoreIcon.svg"
import {
  GAS_FEE_MULTIPLIER,
  MAX_INPUT_AMOUNT,
  MAX_INPUT_FLOATING_POINT,
  STAKING_THRESHOLD
} from "../../constant/constants"
import { makeStyles } from "@mui/styles"
import { AnyAction } from "redux"
import { useWalletWrapper } from "../../context/Wallet"
import Banner from "../Ad/Banner"
import { useTranslation } from "react-i18next"
import { getMaxBalanceEstimateGasFee } from "../../utils/chainService"
import useExchangeRatio from "../../hooks/useExchangeRatio"

const StakeForm = (props: {
  pos: IPosState
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
  dispatch: Dispatch<AnyAction>
  setIsRequestingStake: Dispatch<SetStateAction<boolean>>
  setOpenModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { pos, inputValue, setInputValue, dispatch, setIsRequestingStake, setOpenModal } = props
  const classes = useStyles()
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [displayInput, setDisplayInput] = useState(() => inputValue)
  const [isMaxDisabled, setIsMaxDisabled] = useState(false)
  const { t } = useTranslation()
  const { ttVeTTRatio } = useExchangeRatio()
  const { apy, ttPriceUsd } = pos.projectStatus
  const { balance, walletAddress, showAddress } = pos.userStatus

  const annualEarning =
    isFinite(apy) && apy !== 0 ? Number(inputValue) * apy : Number(inputValue) * 100
  const earningInUsd = annualEarning * ttPriceUsd

  const buttonDisabled =
    showError ||
    isMaxDisabled ||
    (inputValue === "" && showAddress) ||
    (balance === 0 && showAddress)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: any) => {
    const removeChar = event.currentTarget.value.replace(/[^\d.]/g, "").replace(/(\..*)\./g, "$1")
    const [_, decimal] = removeChar.split(".")
    if (
      event.currentTarget.value < 0 ||
      Number(event.currentTarget.value) > MAX_INPUT_AMOUNT ||
      (decimal && decimal.length > MAX_INPUT_FLOATING_POINT)
    )
      return
    setInputValue(removeChar)
    setDisplayInput(compute.formatNumber(removeChar))
  }

  const { setisConnectWalletsModal } = useWalletWrapper()
  const autoConnectInTTWallet = useCallback(async () => {
    if ("hubId" in window) {
      const account: string[] = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: []
      })

      if (account.length > 0) {
        dispatch(updateWalletAddress(account[0]))
        dispatch(updateShowAddress(true))
      }
    }
  }, [dispatch])

  const handleConnectWallet = () => {
    if ("hubId" in window) autoConnectInTTWallet()
    else setisConnectWalletsModal(true)
  }

  const handleStakePressed = () => {
    if (buttonDisabled) return

    setIsRequestingStake(true)
    setOpenModal(true)
  }

  useEffect(() => {
    if (walletAddress !== "" && inputValue !== "") {
      if (Number(inputValue) < STAKING_THRESHOLD) {
        setShowError(true)
        setErrorMessage(`${t("staking.stake.minimum")}`)
      } else if (balance < Number(inputValue)) {
        setShowError(true)
        setErrorMessage(`${t("staking.stake.insufficient")}`)
      } else {
        setShowError(false)
        setErrorMessage("")
      }
    } else if (inputValue === "") {
      setDisplayInput("")
    } else {
      setShowError(false)
      setErrorMessage("")
    }
  }, [inputValue, balance, walletAddress])

  useEffect(() => {
    if (showError) {
      inputRef.current!.style.borderColor = "#E0392D"
    } else {
      inputRef.current!.style.borderColor = ""
    }
  }, [showError])

  return (
    <div
      className="`
      flex flex-col p-[16px] bg-white dark:bg-neutrals-900 rounded-[18px] md:p-[24px]
    `"
    >
      <div className="flex flex-row justify-between">
        <span className="text-[14px] leading-[20px] font-medium">{t("staking.stake.title")}</span>
        <span className="text-secondary text-[12px] leading-[20px]">
          1 TT ≈ {`${compute.roundNumber(ttVeTTRatio, 4)}`} veTT
        </span>
      </div>
      <div
        ref={inputRef}
        className={`
          flex flex-row items-center mt-[8px] space-x-[12px] p-[16px] rounded-[12px] border-[1px] border-solid border-br-light dark:border-neutrals-700 bg-neutrals-50 dark:bg-neutrals-800
        `}
      >
        <img src={inputThunderCoreIcon} className="w-[24px] h-[24px]" alt={"TT"} />
        <input
          id="fname"
          name="fname"
          type="text"
          inputMode="numeric"
          min="0"
          value={isMaxDisabled ? t("staking.stake.calculating") : displayInput}
          readOnly={isMaxDisabled}
          onChange={handleChange}
          className={`${classes.input} text-black dark:text-white`}
          placeholder="0"
          onFocus={() => {
            inputRef.current!.classList.add("dark:border-secondary-500", "border-primary-700")
          }}
          onBlur={() => {
            inputRef.current!.classList.remove("dark:border-secondary-500", "border-primary-700")
          }}
        />
        <button
          onClick={async () => {
            if (balance < GAS_FEE_MULTIPLIER || isMaxDisabled) return
            setIsMaxDisabled(true)
            const gasFee = (await getMaxBalanceEstimateGasFee(balance)) || GAS_FEE_MULTIPLIER
            setInputValue((balance - gasFee).toString())
            setDisplayInput(compute.formatNumber(balance - gasFee))
            setIsMaxDisabled(false)
          }}
          className="btn btn-secondary w-[66px] h-[32px] text-[12px] leading-[20px] rounded-[6px]"
          disabled={isMaxDisabled}
        >
          {t("staking.stake.max")}
        </button>
      </div>
      {showError && (
        <div className="text-error dark:text-dark-error mt-[4px] text-[12px] leading-[20px]">
          {errorMessage}
        </div>
      )}
      <div className="flex flex-row justify-between mt-[32px]">
        <div className="text-[12px] leading-[20px]">{t("staking.stake.youWillGet")}</div>
        <div className="text-[12px] leading-[20px]">
          {`${compute.roundNumberShort(Number(inputValue) * Number(ttVeTTRatio))} veTT`}
        </div>
      </div>
      <div className="flex flex-row justify-between mt-[8px] mb-[24px]">
        <div className="text-[12px] leading-[20px]">{t("staking.stake.estimated")}</div>
        <div className="text-[12px] leading-[20px] flex flex-col items-end justify-center">
          <div>{`≈ ${compute.roundNumberShort(annualEarning)} TT`}</div>
          <div>{`($${compute.roundNumberShort(earningInUsd)} USD)`}</div>
        </div>
      </div>
      <button
        onClick={() => (!showAddress ? handleConnectWallet() : handleStakePressed())}
        disabled={buttonDisabled}
        className="btn btn-secondary w-full h-[48px]"
      >
        {!showAddress ? t("staking.connect.connect") : t("staking.stake.stake")}
      </button>
      <Banner />
    </div>
  )
}
const useStyles = makeStyles({
  input: {
    "&::placeholder": {
      color: "#707488",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500
    },
    fontFamily: "Montserrat",
    width: "100%",
    borderWidth: 0,
    backgroundColor: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    outline: "none",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  }
})
export default StakeForm
